import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/classify',
    name: 'Classify',
    component: () => import('../views/Classify.vue')
  },
  {
    path: '/classifylist/:title',
    name: 'ClassifyList',
    component: () => import('../views/ClassifyList.vue')
  },
  {
    path: '/friendlink',
    name: 'Friendlink',
    component: () => import('../views/Friendlink.vue')
  },
  {
    path: '/liuyan',
    name: 'Liuyan',
    component: () => import('../views/Liuyan.vue')
  },
  {
    path: '/pages/:title',
    name: 'Page',
    component: () => import('../views/Page.vue')
  },
  {
    path: '/aboutme',
    name: 'About',
    component: () => import('../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: () => ({ x: 0, y: 0 }),
})

// 解决重复点击路由报错的BUG
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}
export default router
