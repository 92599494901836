<template>
  <div class="asidecontent">
    <div class="card-widget card-info">
      <div class="card-content">
        <div class="card-info-avatar is-center">
          <img
            class="avatar-img"
            src="https://blog.likepoems.com/images/author.jpg"
          />
          <div class="author-info__name">一缕阳光</div>
          <div class="author-info__description">
            一个收藏回忆与记录学习的地方！
          </div>
        </div>
        <div class="card-info-data">
          <div class="card-info-data-item is-center">
            <a href="https://likepoems.com/#/layout">
              <div class="headline">文章</div>
              <div class="length-num">{{ totalpages }}</div>
            </a>
          </div>
          <div class="card-info-data-item is-center">
            <a href="/classify">
              <div class="headline">分类</div>
              <div class="length-num">{{ totalclassify }}</div>
            </a>
          </div>
        </div>
        <a class="card-info-btn button--animated" href="/">
          <i class="far fa-paper-plane"></i>
          <span>个人主页</span>
        </a>
      </div>
    </div>
    <div class="card-widget card-announcement">
      <div class="card-content">
        <el-calendar v-model="calendarvalue"></el-calendar>
      </div>
    </div>
    <div class="card-widget card-announcement">
      <div class="card-content">
        <div class="item-headline">
          <i class="el-icon-bell card-announcement-animation"></i>
          <span>公告</span>
        </div>
        <div class="announcement_content">
          <div>欢迎访问我的个人博客！</div>
          <br />
          <img src="https://blog.likepoems.com/images/author.gif" />
        </div>
      </div>
    </div>
    <div class="card-widget card-recent-post">
      <div class="card-content">
        <div class="item-headline">
          <i class="el-icon-time"></i><span>最新文章</span>
        </div>
        <div class="aside-list" v-loading="loading">
          <div
            class="aside-list-item"
            :key="index"
            v-for="(item, index) in latestblogdata"
            @click="go(item)"
          >
            <a class="thumbnail"><img :src="baseUrl + item.cover" /></a>
            <div class="content">
              <a class="title">{{ item.title }}</a
              ><time>{{ item.createtime }}</time>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AsideContent",
  data() {
    return {
      loading: false,
      totalpages: "",
      totalclassify: "",
      latestblogdata: [],
      calendarvalue: new Date(),
      baseUrl: "https://backblog.likepoems.com/",
    };
  },
  methods: {
    getlatestblog() {
      this.loading = true;
      this.$axios
        .get("/api/getlatestessay")
        .then((response) => {
          this.totalpages = response.data.totalpages;
          this.totalclassify = response.data.totalclassify;
          this.latestblogdata = response.data.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    go(data) {
      let pagedata = {
        pageid: data._id,
        pageview: data.pageview,
      };
      this.$axios
        .post("/api/pageview", pagedata)
        .then((response) => {
          console.log("success", response);
        })
        .catch((error) => {
          console.log("error", error);
        });
      // console.log(data.title);
      let pagetitle = data.title.replace(/\s*/g, "");
      this.$router.push(`/pages/title=${pagetitle}`);
      localStorage.setItem("blogdetails", JSON.stringify(data));
    },
  },
  mounted() {
    this.getlatestblog();
  },
};
</script>

<style>
.asidecontent {
  width: 25%;
  min-height: 300px;
  height: auto;
  padding-left: 15px;
}

.asidecontent .card-widget {
  background-color: #f6f8fa;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 4px 8px 6px rgba(7, 17, 27, 0.06);
  transition: all 0.3s;
  margin-bottom: 1em;
}
.asidecontent .card-widget:hover {
  -webkit-box-shadow: 0 4px 12px 12px rgba(7, 17, 27, 0.15);
  box-shadow: 0 4px 12px 12px rgba(7, 17, 27, 0.15);
}
.button--animated {
  position: relative;
  z-index: 1;
  transition: color 1s;
  cursor: pointer;
}
.button--animated:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background: #ff7242;
  content: "";
  transition: transform 0.5s ease-out;
  transform: scaleX(0);
  transform-origin: 0 50%;
}
.button--animated:hover:before {
  transition-timing-function: cubic-bezier(0.45, 1.64, 0.47, 0.66);
  transform: scaleX(1);
}
.asidecontent .card-content {
  padding: 1rem 1.2rem;
}
.asidecontent .card-info img {
  width: 110px;
  height: 110px;
  border-radius: 70px;
  transition: all 0.5s;
}
.avatar-img {
  border: 0;
  border-radius: 100%;
  animation: rotate 5s linear infinite;
  margin: auto;
  transition: all 0.5s ease-in-out;
}
.author-info__name {
  font-size: 1.5rem;
  font-weight: 1000;
  background-image: linear-gradient(to right, tomato, #ff0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.asidecontent .card-info .author-info__description {
  margin-top: -0.3rem;
}
.asidecontent .card-info .card-info-data {
  display: table;
  padding: 0.7rem 0;
  width: 100%;
  table-layout: fixed;
}
.asidecontent .card-info .card-info-data > .card-info-data-item {
  display: table-cell;
}
.asidecontent .card-info .card-info-btn {
  display: block;
  background-color: #49b1f5;
  color: #fff;
  text-align: center;
  line-height: 2.4;
}
.asidecontent .card-content {
  padding: 1rem 1.2rem;
}
.asidecontent .item-headline {
  padding-bottom: 0.3rem;
  font-size: 22px;
}
.el-icon-bell,
.el-icon-time {
  font-size: 22px;
  margin-right: 10px;
}
.card-announcement-animation {
  color: #f00;
  animation: announ_animation 0.8s linear infinite;
}
@keyframes announ_animation {
  0%,
  to {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}
.asidecontent .aside-list > .aside-list-item {
  display: flex;
  align-items: center;
  padding: 0.3rem 0;
}
.asidecontent .aside-list > .aside-list-item .thumbnail {
  overflow: hidden;
  width: 4.2em;
  height: 4.2em;
}
.asidecontent .aside-list > .aside-list-item .thumbnail > img {
  width: 100%;
  height: 100%;
  transition: all 0.6s;
  object-fit: cover;
}
.asidecontent .aside-list > .aside-list-item .thumbnail > img:hover {
  transform: scale(1.1);
}
.asidecontent .aside-list > .aside-list-item .content {
  flex: 1;
  padding-left: 10px;
  word-break: break-all;
  color: #4c4948;
  font-size: 95%;
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-line-clamp: 2;
}
.card-recent-post .title,
.card-recent-post .time {
  display: block;
}
.card-recent-post .title:hover {
  color: #49b1f5;
}
@media screen and (max-width: 1182px) {
  .asidecontent .el-calendar {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .asidecontent {
    width: 100% !important;
    padding-left: 0;
  }
  .asidecontent > .card-widget:first-child {
    margin-top: 1rem;
  }
  .asidecontent .el-calendar {
    display: block;
  }
}
</style>