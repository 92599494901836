<template>
  <div class="totop">
    <el-backtop>
      <i class="el-icon-arrow-up" title="回到顶部"></i>
    </el-backtop>
  </div>
</template>
<script>
export default {
  name: "ToTop",
  data() {
    return {};
  },
};
</script>
<style scoped>
.totop /deep/ .el-backtop {
  width: 36px;
  height: 36px;
  /* background-color: #49b1f5; */
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
  border: none;
  right: 20px !important;
  bottom: 20px !important;
}
.totop /deep/ .el-backtop:hover {
  /* background-color: #ff7242; */
  background-color: rgba(0, 0, 0, 0.6);
}
.totop /deep/ .el-backtop {
  border-radius: 0 !important;
}
.totop i {
  font-size: 1.5rem;
}
</style>