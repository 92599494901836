<template>
  <div class="home">
    <Header></Header>
    <div class="layout clearfix" id="layout">
      <RecentPosts></RecentPosts>
      <AsideContent></AsideContent>
    </div>
    <Footer></Footer>
    <ToTop></ToTop>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import RecentPosts from "@/components/RecentPosts";
import AsideContent from "@/components/AsideContent";
import ToTop from "@/components/ToTop";
export default {
  name: "Home",
  components: {
    Header,
    Footer,
    RecentPosts,
    AsideContent,
    ToTop
  }
};
</script>
<style scoped>
.Header {
  background-image: url("https://blog.likepoems.com/images/17.png");
  background-attachment: fixed;
}
.layout {
  margin: 0 auto;
  height: auto;
  padding: 2rem 15px;
  max-width: 1200px;
  display: flex;
}
@media screen and (max-width: 768px) {
  .layout {
    flex-direction: column;
  }
}
</style>
