<template>
  <div class="recentposts" v-loading="loading">
    <div
      class="recent-post-item"
      v-for="(item, index) in blogdata"
      :key="index"
      @click="go(item)"
    >
      <div
        class="post_cover"
        :class="(isTrue = index % 2 ? left_radius : right_radius)"
      >
        <a>
          <img class="post_bg" :src="baseUrl + item.cover" />
        </a>
      </div>
      <div class="recent-post-info">
        <a class="article-title">{{ item.title }}</a>
        <div class="article-meta-wrap">
          <span class="post-meta-date">
            <i class="el-icon-date"></i>
            <span class="article-meta-label">发表于</span>
            <time>{{ item.createtime }}</time>
          </span>
          <span class="article-meta">
            <span class="article-meta__separator">|</span>
            <i class="el-icon-collection-tag"></i>
            <a class="article-meta__categories">{{ item.category }}</a>
          </span>
        </div>
        <div class="content">
          {{
            item.editorvalue | filtersText
          }}
        </div>
      </div>
    </div>

    <div class="pages">
      <el-pagination
        background
        layout="prev, pager, next"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :total="total"
        :page-size="pageSize"
        :pager-count="5"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "recentposts",
  data() {
    return {
      loading: false,
      currentPage: 1, // 当前页
      total: 0, // 数据总条数
      pageSize: 3, // 每页条数
      blogdata: [],
      isTrue: true,
      left_radius: "left_radius",
      right_radius: "right_radius",
      baseUrl: "https://backblog.likepoems.com/",
    };
  },
  methods: {
    // 按照分页显示数据的函数
    getallblogs() {
      this.loading = true;
      // 收集当前页码 和 每页显示条数
      let pageSize = this.pageSize;
      let currentPage = this.currentPage;
      let blogdata = this.blogdata;
      // 发送ajax请求 把分页数据发送给后端
      this.$axios
        .get("/api/getessay", {
          params: {
            pageSize,
            currentPage,
          },
        })
        .then((response) => {
          this.total = response.data.total;
          this.blogdata = response.data.data;
          this.loading = false;
          // 如果当前页没有数据 且 排除第一页
          if (!blogdata.length && this.currentPage !== 1) {
            // 页码减去 1
            this.currentPage -= 1;
            // 再调用自己
            this.getallblogs();
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    // 每页显示条数改变 就会触发这个函数
    handleSizeChange(val) {
      // 保存每页显示的条数
      this.pageSize = val;
      // 调用分页函数
      this.getallblogs();
    },
    // 当前页码改变 就会触发这个函数
    handleCurrentChange(val) {
      // 保存当前页码
      this.currentPage = val;
      // 调用分页函数
      this.getallblogs();
    },
    go(data) {
      let pagedata = {
        pageid: data._id,
        pageview: data.pageview,
      };
      this.$axios
        .post("/api/pageview", pagedata)
        .then((response) => {
          console.log("success", response);
        })
        .catch((error) => {
          console.log("error", error);
        });
      let pagetitle = data.title.replace(/\s*/g, "");
      this.$router.push(`/pages/title=${pagetitle}`);
      localStorage.setItem("blogdetails", JSON.stringify(data));
    },
  },
  filters: {
    filtersText(val) {
      // 要判断一下,如果是空就返回空字符串,不然会报错
      if (val != null && val != "") {
        var reg = /[\u4e00-\u9fa5]/g;
        var names = val.match(reg);
        val = names.join("");
        return val.substr(0, 100); //获取富文本文字内容的前100个字符
      } else return "";
    },
  },
  mounted() {
    this.getallblogs();
  },
};
</script>

<style>
.recentposts {
  width: 75%;
  min-height: 300px;
  height: auto;
  transition: all 0.3s;
}
.recentposts > .recent-post-item {
  width: 100%;
  height: 20em;
  display: flex;
  border-radius: 12px 8px 8px 12px;
  box-shadow: 0 4px 8px 6px rgba(7, 17, 27, 0.06);
  transition: all 0.3s;
  align-items: center;
  flex-direction: row;
  background-color: #f6f8fa;
  margin-bottom: 1em;
}
.recentposts > .recent-post-item .post_cover {
  overflow: hidden;
  width: 45%;
  height: 100%;
}
.recentposts > .recent-post-item .post_cover img.post_bg {
  width: 100%;
  height: 100%;
  transition: all 0.6s;
  object-fit: cover;
}
.recentposts > .recent-post-item .post_cover img.post_bg:hover {
  transform: scale(1.1);
}
.recentposts > .recent-post-item > .recent-post-info {
  display: inline-block;
  overflow: hidden;
  padding: 0 40px;
  width: 55%;
}
.recentposts > .recent-post-item > .recent-post-info > .article-title {
  margin-bottom: 0.3rem;
  color: #1f2d3d;
  font-size: 1.72em;
  line-height: 1.4;
  transition: all 0.2s ease-in-out;
}
.recentposts > .recent-post-item > .recent-post-info > .article-title:hover {
  color: #49b1f5;
}
.recentposts > .recent-post-item > .recent-post-info > .article-meta-wrap {
  color: #858585;
  font-size: 90%;
}
.recentposts > .recent-post-item > .recent-post-info > .content {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  margin-top: 0.3rem;
  -webkit-line-clamp: 3;
}
.recentposts > .recent-post-item:hover {
  -webkit-box-shadow: 0 4px 12px 12px rgba(7, 17, 27, 0.15);
  box-shadow: 0 4px 12px 12px rgba(7, 17, 27, 0.15);
}
.recentposts > .recent-post-item > .recent-post-info > .article-meta-wrap i {
  margin: 0 0.2rem 0 0;
}
.recentposts
  > .recent-post-item
  > .recent-post-info
  > .article-meta-wrap
  .article-meta-label {
  padding-right: 0.2rem;
}
.recentposts
  > .recent-post-item
  > .recent-post-info
  > .article-meta-wrap
  .article-meta__separator {
  margin: 0 0.3rem;
}
.recentposts > .recent-post-item > .recent-post-info > .article-meta-wrap a {
  color: #858585;
}
.recentposts
  > .recent-post-item
  > .recent-post-info
  > .article-meta-wrap
  a:hover {
  text-decoration: underline;
  color: #49b1f5;
}
.pages {
  margin-top: 1em;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .recentposts {
    width: 100% !important;
  }
  .recentposts .recent-post-item {
    flex-direction: column;
    height: auto !important;
  }
  .recentposts .recent-post-item .post_cover {
    order: 1 !important;
    width: 100%;
    height: 230px;
    border-radius: 8px 8px 0 0;
  }
  .recentposts .recent-post-item .recent-post-info {
    order: 2 !important;
    padding: 1rem 1rem 1.5rem;
    width: 100%;
  }
}
</style>