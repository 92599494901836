<template>
  <div class="Header">
    <div class="blankdiv" ref="blankdiv" @click.stop="showAsidebar"></div>
    <div class="nav">
      <div class="logo">一缕阳光</div>
      <div id="menus">
        <div class="menus_items">
          <el-menu
            :default-active="activeIndex"
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect"
            active-text-color="#49b1f5"
          >
            <el-menu-item index="">
              <i class="el-icon-house"></i>
              <span slot="title">首页</span>
            </el-menu-item>
            <el-menu-item index="classify">
              <i class="el-icon-collection-tag"></i>
              <span slot="title">分类</span>
            </el-menu-item>
            <el-menu-item index="friendlink">
              <i class="el-icon-link"></i>
              <span slot="title">链接</span>
            </el-menu-item>
            <el-menu-item index="liuyan">
              <i class="el-icon-message"></i>
              <span slot="title">留言</span>
            </el-menu-item>
            <el-menu-item index="aboutme">
              <i class="el-icon-sugar"></i>
              <span slot="title">关于</span>
            </el-menu-item>
          </el-menu>
          <div id="search_button" @click.stop="showSearch">
            <a>
              <i class="fas fa-search fa-fw"></i>
              <span>搜索</span>
            </a>
          </div>
          <div id="toggle-menu" @click.stop="showAsidebar">
            <a>
              <i class="fas fa-bars fa-fw"></i>
              <span>菜单</span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div id="sidebar-menus" v-show="isShow">
      <div class="author-avatar">
        <img
          class="avatar-img"
          src="https://blog.likepoems.com/images/author.jpg"
        />
      </div>
      <div class="site-data">
        <div class="data-item is-center">
          <div class="data-item-link">
            <a href="/">
              <div class="headline">文章</div>
              <div class="length-num">{{ totalpages }}</div>
            </a>
          </div>
        </div>
        <div class="data-item is-center">
          <div class="data-item-link">
            <a href="/classify">
              <div class="headline">分类</div>
              <div class="length-num">{{ totalclassify }}</div>
            </a>
          </div>
        </div>
      </div>
      <hr />
      <div class="menus_items">
        <el-row class="tac">
          <el-col>
            <el-menu
              :default-active="activeIndex"
              class="el-menu-vertical-demo"
              @select="handleSelect"
              active-text-color="#49b1f5"
            >
              <el-menu-item index="" class="menus_items">
                <i class="fa-fw fa fa-home"></i>
                <span slot="title">首页</span>
              </el-menu-item>
              <el-menu-item index="classify" class="menus_items">
                <i class="fa-fw fa fa-tags"></i>
                <span slot="title">分类</span>
              </el-menu-item>
              <el-menu-item index="friendlink" class="menus_items">
                <i class="fa-fw fa fa-link"></i>
                <span slot="title">链接</span>
              </el-menu-item>
              <el-menu-item index="liuyan" class="menus_items">
                <i class="fa-fw fa fa-coffee"></i>
                <span slot="title">留言</span>
              </el-menu-item>
              <el-menu-item index="aboutme" class="menus_items">
                <i class="fa-fw fa fa-heartbeat"></i>
                <span slot="title">关于</span>
              </el-menu-item>
            </el-menu>
          </el-col>
        </el-row>
      </div>
    </div>
    <div id="site-info">
      <h1 id="site-title" ref="pagedetail">一缕阳光</h1>
      <div id="site-subtitle">
        <span id="subtitle" ref="subtitle"></span>
        <span class="typed-cursor" aria-hidden="true">|</span>
      </div>
    </div>
    <div class="search-dialog" v-show="isSearch" ref="searchdialog">
      <div class="search-dialog__title">文章搜索</div>
      <div class="local-search-box">
        <input
          class="local-search-box--input"
          placeholder="搜索文章"
          type="text"
          v-model="searchPageVal"
        />
      </div>
      <hr />
      <div class="local-search-results">
        <div class="search-result-list" ref="searchnoneResult">
          <div
            class="local-search__hit-item"
            v-for="(item, index) in searchResult"
            :key="index"
            @click="go(item)"
          >
            <a class="search-result-title">{{ item.title }}</a>
            <p class="search-result">
              {{
                item.editorvalue
                  .replace(/&lt;\/?[^>]*&gt;/gi, "")
                  .replace(/[\r\n]/gi, "")
                  .replace(/\s+/gi, "")
                  .replace(/&nbsp;/gi, "")
              }}
            </p>
          </div>
        </div>
      </div>
      <span class="search-close-button" @click.stop="showSearch">
        <i class="fas fa-times"></i>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      index: "",
      isShow: false,
      isSearch: false,
      totalpages: "",
      totalclassify: "",
      screenWidth: document.body.clientWidth,
      searchPageVal: "",
      searchResult: "",
    };
  },
  methods: {
    setfontRollback(motto) {
      var types = [
        "麻雀虽小，五脏俱全",
        "前人栽树，后人乘凉",
        "千丈麻绳，终须有结",
        "宝镜磨清，只照一室；心镜磨清,可照六合",
        "今日事，今日毕",
        "要成功，先发疯，头脑简单向前冲",
        "书山有路勤为径，学海无涯苦作舟",
        "不为失败找借口，要为成功找方法",
        "笑看人生峰高处，唯有磨难多正果",
        "不经历风雨，怎能见彩虹",
        "今天多一份努力，明天多一份欢笑",
        "再苦再难，也要坚持，只为那些期待的眼神"
      ];
      motto ? (types = motto) : types;
      var words = this.$refs.subtitle;
      var stopType = false; //用于停止自动打字的
      var index = 0;
      var tempWords = "";
      var isNext = false;
      var time = 200;
      var startType = setInterval(function () {
        if (stopType) {
          //如果需要停止打字
          clearInterval(startType);
        }
        if (tempWords.length === 0) {
          //如果删完了，就开始
          if (isNext) {
            index++;
            index = index % 12;
            isNext = false;
          }
          tempWords = types[index].substring(0, tempWords.length + 1);
        } else if (
          tempWords.length === types[index].length &&
          isNext === false
        ) {
          //如果已经到头了，就要删去
          isNext = true;
          time = 5000;
        } else {
          //如果既没删完也没显示完
          if (isNext) {
            //如果是在减少
            tempWords = tempWords.substring(0, tempWords.length - 1);
            time = 150;
          } else {
            //如果在增多
            time = 200;
            tempWords = types[index].substring(0, tempWords.length + 1);
          }
        }
        words.innerText = tempWords;
      }, time);
    },
    handleSelect(key) {
      switch (key) {
        case "":
          this.$router.push("/");
          break;
        case "classify":
          this.$router.push("/classify");
          break;
        case "friendlink":
          this.$router.push("/friendlink");
          break;
        case "liuyan":
          this.$router.push("/liuyan");
          break;
        case "aboutme":
          this.$router.push("/aboutme");
          break;
      }
    },
    showAsidebar() {
      this.isShow = !this.isShow;
      if (this.isShow) {
        this.$refs.blankdiv.style.display = "block";
        this.$refs.blankdiv.style.backgroundColor = "rgba(0, 0, 0, 0.8)";
      } else {
        this.$refs.blankdiv.style.display = "none";
        this.$refs.blankdiv.style.backgroundColor = "none";
      }
    },
    getlatestblog() {
      this.$axios
        .get("/api/getlatestessay")
        .then((response) => {
          this.totalpages = response.data.totalpages;
          this.totalclassify = response.data.totalclassify;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    showSearch() {
      this.isSearch = !this.isSearch;
      if (this.isSearch) {
        this.$refs.searchdialog.style.display = "block";
        this.$refs.blankdiv.style.display = "block";
      } else {
        this.$refs.searchdialog.style.display = "none";
        this.$refs.blankdiv.style.display = "none";
      }
    },
    searchPage() {
      let self = this;
      self.$watch("searchPageVal", (val) => {
        self.$axios
          .get("/api/searchpages", {
            params: { searchKey: val },
          })
          .then((response) => {
            this.searchResult = response.data;
            if (this.searchResult.length == 0 && val) {
              this.$refs.searchnoneResult.innerText =
                "找不到您查询的内容：" + val;
              this.$refs.searchnoneResult.style.color = "#000";
            } else {
              this.$refs.searchnoneResult.innerText = "";
            }
          })
          .catch((error) => {
            console.log("error", error);
          });
      });
    },
    go(data) {
      console.log(data);
      let pagedata = {
        pageid: data._id,
        pageview: data.pageview,
      };
      this.$axios
        .post("/api/pageview", pagedata)
        .then((response) => {
          console.log("success", response);
        })
        .catch((error) => {
          console.log("error", error);
        });
      let pagetitle = data.title.replace(/\s*/g, "");
      this.$router.push(`/pages/title=${pagetitle}`);
      localStorage.setItem("blogdetails", JSON.stringify(data));
    },
  },
  computed: {
    activeIndex() {
      return this.$route.path.replace("/", "");
    },
  },
  watch: {
    screenWidth(newVal) {
      if (newVal > 768) {
        this.isShow = false;
        this.$refs.blankdiv.style.display = "none";
        this.$refs.blankdiv.style.backgroundColor = "none";
      }
    },
    searchPageVal(val) {
      this.searchPageVal = val;
    },
  },
  mounted() {
    this.setfontRollback();
    this.getlatestblog();
    this.searchPage();

    const that = this;
    window.onresize = () => {
      window.screenWidth = document.body.clientWidth;
      that.screenWidth = window.screenWidth;
    };
  },
};
</script>

<style scoped>
.Header {
  width: 100%;
  height: 100%;
  height: 100vh;
  position: relative;
  background-color: #49b1f5;
  background-size: cover;
  background-repeat: no-repeat;
  transition: all 0.5s;
  background-position: center center;
  color: #fff;
}
.nav {
  position: absolute;
  top: 0;
  z-index: 90;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 36px;
  width: 100%;
  height: 60px;
  font-size: 1.3em;
  transition: all 0.5s;
}
.Header .logo {
  flex: 1;
  text-shadow: 0.1rem 0.1rem 0.2rem rgba(0, 0, 0, 0.15);
  font-weight: bold;
  cursor: pointer;
  font-size: 1.5rem;
  line-height: 40px;
}

#menus {
  padding-right: 0 !important;
  overflow: hidden;
}
.el-menu,
.el-menu li:hover {
  background: none;
}
.el-menu--horizontal {
  display: inline-block;
}
.el-menu--horizontal > .el-menu-item {
  position: relative;
  display: inline-block;
  padding: 0 0.7rem;
}
.el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
.el-menu--horizontal > .el-submenu .el-submenu__title:hover {
  background: none;
  color: #49b1f5;
}
.el-menu--horizontal > .el-menu-item {
  color: white;
  height: 40px;
  line-height: 40px;
}
.el-menu /deep/.el-menu-item i {
  color: #fff;
}
.el-menu.el-menu--horizontal {
  border: none;
  float: left;
}
#toggle-menu {
  display: none;
  padding: 0 0.7rem;
  padding-right: 0;
  vertical-align: top;
}
#toggle-menu a {
  font-size: 0.78em;
  cursor: pointer;
  color: #fff;
  height: 40px;
  line-height: 40px;
}
#toggle-menu a i {
  font-size: 18px;
  vertical-align: middle;
  margin-right: 6px;
}
#toggle-menu .el-menu--horizontal > .el-menu-item {
  color: white;
  height: 40px;
  line-height: 40px;
}
#site-info {
  position: absolute;
  top: 43%;
  width: 100%;
  text-align: center;
  text-shadow: 0.1rem 0.1rem 0.2rem rgba(0, 0, 0, 0.15);
}
#site-info h1 {
  font-size: 2.85em;
  margin: 0;
  color: white;
  line-height: 1.2;
}
#site-info #subtitle {
  font-size: 1.15em;
  color: #eee;
}
#search_button {
  float: left;
  height: 40px;
  line-height: 45px;
}
#search_button a {
  color: #fff;
  padding: 0 0.7rem;
  position: relative;
  text-shadow: 0.05rem 0.05rem 0.1rem rgba(0, 0, 0, 0.3);
  font-size: 0.78em;
}
#search_button i {
  margin-right: 5px;
}
@media screen and (max-width: 768px) {
  .nav {
    width: 100%;
  }
  #menus ul {
    display: none !important;
  }
  .nav #toggle-menu {
    display: block;
    float: right;
  }
  #search_button {
    float: left;
    height: 40px;
    line-height: 45px;
    vertical-align: middle;
    display: table-cell;
  }
  .nav #toggle-menu {
    display: table-cell;
    float: right;
    vertical-align: middle;
    height: 40px;
    line-height: 45px;
  }
  #toggle-menu .site-page i {
    vertical-align: inherit;
    margin-right: 6px;
  }
  .search-dialog {
    top: 0;
    left: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    border-radius: 0;
  }
  .search-dialog .search-result-list {
    padding-bottom: 2rem;
    overflow-y: auto;
    max-height: 75vh !important;
  }
}
</style>
