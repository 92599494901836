import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    blogdetails: {
      msg:'这是文章的初始内容'
    }
  },
  mutations: {
    setblogdetails(state, blogdetails) {
      state.blogdetails = blogdetails
    }
  },
  actions: {
  },
  modules: {
  }
})
