<template>
  <div class="footer">
    <div class="footer-wrap">
      <p>©2021 by likepoems</p>
      <p>本站基于vue引擎</p>
      <p ref="yiyan"></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {};
  },
  mounted() {
    this.getyiyan();
  },
  methods: {
    getyiyan() {
      this.$axios.get("https://v1.hitokoto.cn").then((res) => {
        this.$refs.yiyan.innerText = res.data.hitokoto;
      });
    },
  },
};
</script>

<style scoped>
.footer {
  position: relative;
  background: linear-gradient(-45deg, #ee7752, #ce3e75, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: Gradient 10s ease infinite;
  user-select: none;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  min-height: 140px;
}
@keyframes Gradient {
  0% {
    background-position: 0 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}
.footer-wrap {
  position: relative;
  padding: 2rem 1rem;
  text-align: center;
}
.footer-wrap {
  color: #fff !important;
  font-weight: lighter;
}
</style>